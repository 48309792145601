<template>
  <main>
    <div class="">
      <h2 class="my-2 font-semibold text-blue-800 text-2xl">Downloads</h2>
      <div class="bg-blue-100 p-5">
        <p>
          Please note that all downloads are in .txt formats. You will need to
          change it to .csv format after downloading it. Merci.
        </p>
      </div>
      <div class="bg-white my-4">
        <nav class="flex flex-col sm:flex-row">
          <button
            v-for="(tab, i) in tabs"
            :key="i"
            @click="switchTab(tab)"
            :class="
              current.index === tab.index
                ? 'border-gray-900 border-b-2 font-medium text-gray-900'
                : ''
            "
            class="py-4 px-6 block focus:outline-none hover:text-gray-900"
          >
            {{ tab.name }}
          </button>
        </nav>
      </div>
      <div class="">
        <d-paymentlogs v-if="current.index === 0"></d-paymentlogs>
        <d-users v-if="current.index === 1"></d-users>
        <d-business v-if="current.index === 2"></d-business>
        <d-sales v-if="current.index === 3"></d-sales>
        <d-invoices v-if="current.index === 4"></d-invoices>
        <d-orders v-if="current.index === 5"></d-orders>
        <d-stores v-if="current.index === 6"></d-stores>
        <d-products v-if="current.index === 7"></d-products>
      </div>
    </div>
  </main>
</template>

<script>
import { json2excel } from "js2excel";
import moment from "moment";

import PaymentLogs from "./downloads-components/PaymentLogs";
import Businesses from "./downloads-components/Businesses";
import Invoices from "./downloads-components/Invoices";
import Orders from "./downloads-components/Orders";
import Products from "./downloads-components/Products";
import Sales from "./downloads-components/Sales";
import Stores from "./downloads-components/Stores";
import Users from "./downloads-components/Users";

export default {
  components: {
    "d-paymentlogs": PaymentLogs,
    "d-business": Businesses,
    "d-invoices": Invoices,
    "d-orders": Orders,
    "d-products": Products,
    "d-sales": Sales,
    "d-stores": Stores,
    "d-users": Users,
  },
  data() {
    return {
      moment,
      isLoading: false,
      tabs: [
        {
          name: "Payment Logs",
          index: 0,
          //   dispatchName: "FETCH_USERS",
          //   searchParam: "user's name",
        },
        {
          name: "Users",
          index: 1,
          //   dispatchName: "FETCH_USERS",
          //   searchParam: "user's name",
        },
        {
          name: "Businesses",
          index: 2,
          //   dispatchName: "FETCH_BUSINESSES",
          //   searchParam: "business name",
        },
        {
          name: "Sales",
          index: 3,
          //   dispatchName: "FETCH_SALES",
          //   searchParam: "sale",
        },
        {
          name: "Invoices",
          index: 4,
          //   dispatchName: "FETCH_INVOICES",
          //   searchParam: "sent email",
        },
        {
          name: "Orders",
          index: 5,
          //   dispatchName: "FETCH_ORDERS",
          //   searchParam: "billing customer",
        },
        {
          name: "Stores",
          index: 6,
          //   dispatchName: "FETCH_STORES",
          //   searchParam: "store name",
        },
        {
          name: "Products",
          index: 7,
          //   dispatchName: "FETCH_PRODUCTS",
          //   searchParam: "product",
        },
      ],
      dataset: null,
      current: null,
    };
  },
  computed: {},
  async created() {
    this.current = this.tabs[0];
    // this.fetchData();
  },

  methods: {
    async switchTab(tab) {
      this.current = tab;
      this.dataset = null;
      //   await this.fetchData();
    },
  },
};
</script>

<style></style>
